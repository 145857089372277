<template>
  <div>Template Banner</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TemplateBanner',
  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    showSlider: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      currentPage: 'GET_CURRENT_PAGE',
      isLoginMarketer: 'GET_IS_LOGIN_MARKETER',
    }),
    hideDealCondition() {
      const { isAgencyLogin, isOdysseySite, isLoginMarketer } = this;
      return isAgencyLogin && !isOdysseySite && !isLoginMarketer;
    },
  },
  data() {
    return {
      slide: 0,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };
  },
  mounted() {
    // console.log(this.imageList);
  },
};
</script>
